function _type_of(obj) {
    "@swc/helpers - typeof";
    return obj && typeof Symbol !== "undefined" && obj.constructor === Symbol ? "symbol" : typeof obj;
}
export var PER_PAGE_WIDGET = 3;
export var WELCOME_MODAL_FLAGS = {
    SHOW_ROBILL_SMART_LAUNCH: "showRobillSmartLaunch",
    SHOW_WELCOME: "showWelcome"
};
export function handleWelcomeModalByFeatureFlag(featureFlags) {
    //! Will also
    //? Used to let the dashboard render the welcome modal (depending on true feature flag)
    //* No more than 1 can be true at the same time - After we get it once 'true' the backend will set it to 'false'
    if (!featureFlags || (typeof featureFlags === "undefined" ? "undefined" : _type_of(featureFlags)) !== "object") {
        return;
    }
    var matchedFlag = Object.values(WELCOME_MODAL_FLAGS).find(function(flag) {
        return featureFlags[flag] === true;
    });
    if (matchedFlag) {
        return matchedFlag;
    }
}
