export var ACTIVATE_ANAF_SPV_ERRORS = {
    POSTMESSAGE_ANAF_EFACTURA_ERROR: "robill:anaf-efactura-error",
    POSTBACK_ACCESS_DENIED_ERROR: "access_denied",
    POSTBACK_MISSING_CODE_ERROR: "missing-code",
    POSTBACK_ALREADY_ACTIVE_ERROR: "already-active",
    POSTBACK_SYSTEM_EXISTS_ERROR: "system-exists",
    POSTBACK_ACCESS_TOKEN_ERROR: "access-token",
    POSTBACK_GENERIC_ERROR: "generic-error",
    VALIDATION_ACCESS_DENIED_ERROR: "access-denied",
    VALIDATION_TOKEN_REQUIRED_ERROR: "required",
    VALIDATION_TOKEN_EXPIRED_ERROR: "expired",
    VALIDATION_TOKEN_INVALID_ERROR: "invalid",
    VALIDATION_ALREADY_ACTIVE_ERROR: "hasAnafSpv",
    NOT_FOUND_ERROR: "notFound"
};
export var EFACTURA_LISTING_EVENT_TYPES = {
    SUPPLIER_INVOICE: "FACTURA PRIMITA"
};
export var ANAF_SPV_CONNECTION_WINDOW_API_PATH = {
    ACCOUNTANT_ACTIVATION: "/v1/anaf-spv/accountant/start",
    USER_ACTIVATION: "/v1/my-companies/:myCompanyId/anaf-spv/start"
};
