export var STANDARD_CODES = {
    OISN_9541_PART2: "0010",
    CODE_DESIGNER_OSI_AMATEUR_RADIO: "0011",
    ECMA: "0012",
    EWOS: "0016",
    SWIFT: "0021",
    ISO_6523: "0028",
    UTC: "0064",
    ICD_FORMATTED: "0073",
    EAN_LOCATION: "0088",
    INTERNET_ISO_6523_ICD_ENCODING: "0090",
    CISCO_OSI_NETWORK: "0091",
    ISO_REGISTER_STANDARD_PRODUCING_ORG: "0112",
    GTE_OSI_NETWORK: "0126",
    GLOBAL_BUSINESS_IDENTIFIER: "0149",
    GTIN: "0160"
};
/**
 * Product code types
 */ export var PRODUCT_CODE_TYPES = {
    IDENTIFIER_CODE: "identifier-code",
    ACCOUNTING_CODE: "accounting-code",
    PRODUCT_CODE: "product-code",
    STANDARD_CODE: "standard-code",
    MY_COMPANY_PRODUCT_CODE: "my-company-product-code",
    PARTNER_PRODUCT_CODE: "partner-product-code"
};
