function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { useTranslation } from "react-i18next";
import handleReportError from "../features/errors/handleReportError";
import { isCancel } from "axios";
export var httpStatusCodes = {
    MAINTENANCE: 503,
    THROTTLE: 429,
    VALIDATION: 422,
    OUTDATED: 426,
    NOT_AUTHORIZED: 403,
    NOT_FOUND: 404
};
export var errorsTypes = {
    INTERNAL_SERVER_ERROR: "internalServerError",
    MAINTENANCE: "maintenance",
    THROTTLE: "throttled",
    OUTDATED: "outdated",
    NO_INTERNET_CONNECTION_ERROR: "noInternetConnection",
    NOT_AUTHORIZED: "notAuthorized",
    NOT_FOUND: "notFound"
};
export function mapErrorByHttpStatusCode(errorWithResponse) {
    var customErrors = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if (errorWithResponse && isCancel(errorWithResponse)) {
        return;
    }
    if (errorWithResponse && errorWithResponse.message === errorsTypes.NO_INTERNET_CONNECTION_ERROR) {
        return _define_property({}, errorsTypes.NO_INTERNET_CONNECTION_ERROR, true);
    }
    if (!errorWithResponse.response || !errorWithResponse.response.status) {
        handleReportError(errorWithResponse);
        return _define_property({}, errorsTypes.INTERNAL_SERVER_ERROR, true);
    }
    switch(errorWithResponse.response.status){
        case httpStatusCodes.VALIDATION:
            return errorWithResponse.response.data.errors;
        case httpStatusCodes.MAINTENANCE:
            return _define_property({}, errorsTypes.MAINTENANCE, true);
        case httpStatusCodes.THROTTLE:
            return _define_property({}, errorsTypes.THROTTLE, true);
        case httpStatusCodes.OUTDATED:
            return _define_property({}, errorsTypes.OUTDATED, true);
        case httpStatusCodes.NOT_AUTHORIZED:
            return _define_property({}, errorsTypes.NOT_AUTHORIZED, true);
        case httpStatusCodes.NOT_FOUND:
            return _define_property({}, errorsTypes.NOT_FOUND, true);
        default:
            if (Object.keys(customErrors).includes(errorWithResponse.response.status.toString())) {
                return _define_property({}, customErrors[errorWithResponse.response.status], true);
            }
            handleReportError(errorWithResponse);
            return _define_property({}, errorsTypes.INTERNAL_SERVER_ERROR, true);
    }
}
export default function useErrorMessage(errors) {
    var customErrors = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    var _useTranslation = useTranslation(), translate = _useTranslation.t;
    return getErrorMessageTranslated({
        errors: errors,
        customErrors: customErrors,
        translate: translate
    });
}
export function getErrorMessageTranslated(param) {
    var _errors = param.errors, customErrors = param.customErrors, translate = param.translate;
    /**
     * We want to support strings so we can translate errors we set like this
     *   formik.setFieldError("amount", Object.keys(mapErrorByHttpStatusCode(error) || {})[0]);
     * */ var errors = typeof _errors === "string" ? [
        _errors
    ] : Object.keys(_errors || {});
    var _obj;
    var errorMessages = _object_spread_props(_object_spread({}, customErrors), (_obj = {}, _define_property(_obj, errorsTypes.INTERNAL_SERVER_ERROR, translate("common.errors.internal-server-error")), _define_property(_obj, errorsTypes.THROTTLE, translate("common.errors.throttle")), _define_property(_obj, errorsTypes.MAINTENANCE, translate("common.errors.maintenance")), _define_property(_obj, errorsTypes.OUTDATED, translate("common.errors.outdated")), _define_property(_obj, errorsTypes.NO_INTERNET_CONNECTION_ERROR, translate("common.errors.no-internet-connection")), _define_property(_obj, errorsTypes.NOT_AUTHORIZED, translate("common.errors.not-authorized")), _define_property(_obj, errorsTypes.NOT_FOUND, translate("common.errors.not-found")), _obj));
    return errorMessages[Object.keys(errorMessages).find(function(k) {
        return errors.includes(k);
    })];
}
